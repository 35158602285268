import { For, JSXElement, mergeProps, Show } from 'solid-js';
import * as DropdownMenu from './Base.tsx';

export type ItemType = {
  label: JSXElement;
  class?: string;
  disabled?: boolean;
  onClick?: (e?: MouseEvent) => void;
};

export type ItemGroupType = {
  label?: JSXElement;
  items?: Array<ItemType>;
  hasLabelSeparator?: boolean;
  hasGroupEndSeparator?: boolean;
  class?: string;
};

type MenuProps = {
  id: string; // A unique identifier for the dropdown
  children: JSXElement;
  groups: Array<ItemGroupType>;
  containerClass?: string;
  closeOnSelect?: boolean;
};

export default function Menu(props: MenuProps): JSXElement {
  const merged = mergeProps(
    {
      items: [],
      containerClass: '',
      closeOnSelect: true
    },
    props
  );

  return (
    <DropdownMenu.Root closeOnSelect={merged.closeOnSelect}>
      <DropdownMenu.Trigger asChild>{merged.children}</DropdownMenu.Trigger>
      <DropdownMenu.Positioner>
        <DropdownMenu.Content class={merged.containerClass}>
          <For each={merged.groups}>
            {(group, index) => {
              const groupId = `${merged.id}-group-${index()}`;
              return (
                <DropdownMenu.ItemGroup
                  id={groupId}
                  class={group.class}
                >
                  <Show when={group.label}>
                    <DropdownMenu.ItemGroupLabel
                      for={groupId}
                      class={`py-0.5`}
                    >
                      {group.label}
                    </DropdownMenu.ItemGroupLabel>
                  </Show>
                  <Show when={!!group.hasLabelSeparator}>
                    <DropdownMenu.Separator />
                  </Show>
                  <For each={group.items}>
                    {(item, itemIndex) => {
                      const itemId = `${groupId}-item-${itemIndex()}`;
                      return (
                        <DropdownMenu.Item
                          class={item.class}
                          id={itemId}
                          disabled={!!item.disabled}
                          onClick={(e) => item?.onClick?.(e)}
                        >
                          {item.label}
                        </DropdownMenu.Item>
                      );
                    }}
                  </For>
                  <Show when={!!group.hasGroupEndSeparator}>
                    <DropdownMenu.Separator class={`mt-2`} />
                  </Show>
                </DropdownMenu.ItemGroup>
              );
            }}
          </For>
        </DropdownMenu.Content>
      </DropdownMenu.Positioner>
    </DropdownMenu.Root>
  );
}
