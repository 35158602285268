import { ConnectionData, DirectoryProviders } from '~/api/types/connectionData';
import logger from '../logger';

export enum ClientNotificationEventTypes {
  SSO_CONNECTION_ENABLED = 'ORGANIZATION_SSO_ENABLED',
  SSO_CONNECTION_DISABLED = 'ORGANIZATION_SSO_DISABLED',
  DIRECTORY_ENABLED = 'ORGANIZATION_DIRECTORY_ENABLED',
  DIRECTORY_DISABLED = 'ORGANIZATION_DIRECTORY_DISABLED'
}

type SSOConnectionNotificationData = Pick<
  ConnectionData,
  'id' | 'type' | 'provider' | 'enabled'
> & { connection_type?: string };

type SCIMConnectionNotificationData = {
  id?: string;
  enabled?: boolean;
  directory_type?: string;
  provider?: DirectoryProviders;
};

export type SSOConnectionNotificationPayload = {
  event_type: ClientNotificationEventTypes;
  object: string;
  data: SSOConnectionNotificationData;
  organization_id?: string;
  message?: string;
};

export type SCIMConnectionNotificationPayload = Omit<
  SSOConnectionNotificationPayload,
  'data'
> & {
  data: SCIMConnectionNotificationData;
};

export const postClientNotificationMessage = (message?: any) => {
  try {
    window.parent?.postMessage(message, '*');
  } catch (error) {
    logger.error('Error posting iframe message to parent window', error);
  }
};
