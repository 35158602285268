import {
  createEffect,
  createSignal,
  JSXElement,
  Show,
  untrack
} from 'solid-js';

// TODO - improvement
// This ovelay component can be used to serve the guided tour usecases
// Take the list of ElementById and show cutouts in steps if that is required
// It can return the current step
export default function QuickStartOverlay(props: {
  elementId: string;
  class: string;
  children?: JSXElement;
}): JSXElement {
  const [dimension, setDimension] = createSignal(new DOMRect());
  createEffect(() => {
    const headerLeftSection = document.getElementById(props.elementId);
    if (!!headerLeftSection && !dimension().x) {
      untrack(() => {
        setDimension(headerLeftSection?.getBoundingClientRect());
      });
    }
  });
  return (
    // Overlay screen - z-index: 1300
    // Ovelaymodal - z-index: 1400
    <div class="fixed top-0 left-0 w-screen h-screen z-[1300]">
      <div
        id="PointerAndDescription"
        class={props.class}
      >
        <Show when={dimension().x}>
          <div>{props.children}</div>
        </Show>
      </div>
      <div
        style={{
          'position': 'absolute',
          'background': 'transparent',
          'top': `${dimension().y - 2}px`,
          'left': `${dimension().x - 2}px`,
          'width': `${dimension().width + 4}px`,
          'height': `${dimension().height + 4}px`,
          'box-shadow': '0 0 0 9999px rgba(0, 0, 0, 0.8)'
        }}
      />
    </div>
  );
}
