import {
  createEffect,
  createSignal,
  JSXElement,
  Match,
  mergeProps,
  Show,
  splitProps,
  Switch,
  untrack
} from 'solid-js';
import delve from 'dlv';
import { FormLabel } from '~/components/ui/FormLabel.tsx';
import { Input, InputProps } from '~/components/ui/Input.tsx';
import { getRandomId } from '~/utils/commonUtils.ts';
import { useFormContext } from '~/components/Form/FormContext.tsx';
import { Textarea, TextareaProps } from '~/components/ui/TextArea.tsx';
import { PencilLine, XIcon } from 'lucide-solid';

type ElementProps = InputProps | TextareaProps;

type ComponentProps = {
  label?: JSXElement;
  labelSize?: 'sm' | 'md' | 'lg' | 'xl';
  hint?: JSXElement;
  type?: string;
  editWithConsent?: boolean;
  onEditClick?: () => void;
  onEditCancel?: () => void;
  suffixText?: string;
};

type InputFieldProps = ComponentProps & ElementProps;

const DEFAULT_LABEL_SIZE = 'md';

export default function InputField(props: InputFieldProps) {
  const merged: InputFieldProps = mergeProps(
    {
      type: 'text',
      id: getRandomId()
      // autocomplete: 'off' // @todo remove
    },
    props
  );

  if (!merged.name) {
    console.warn(
      new Error(
        'Missing mandatory field `name` in component <InputField /> . Error messaging wont work'
      ).message
    );
  }

  const formAccessors = useFormContext();

  const [fieldError, setFieldError] = createSignal<Array<string>>([]);
  const [isDisabled, setIsDisabled] = createSignal(
    !!merged.disabled || !!merged.editWithConsent
  );

  createEffect(() => {
    if (merged.name) {
      const error = delve(formAccessors?.errors(), merged.name, '');
      if (!!error && error.length) {
        // the setTimeout is added to defer setting the error by 200ms
        setTimeout(
          (fieldName, { errors }) => {
            const fieldError = delve(errors(), fieldName, '');
            if (!!fieldError && fieldError.length) {
              setFieldError(fieldError);
            } else {
              setFieldError([]);
            }
          },
          200,
          merged.name,
          formAccessors
        );
      } else {
        // @todo might need optimisation later. Need to validate in larger forms
        setFieldError([]);
      }
    }
  });

  createEffect(() => {
    if (
      merged.editWithConsent &&
      !formAccessors.isChanged() &&
      !!formAccessors.data()[merged.name!]
    ) {
      untrack(() => setIsDisabled(true));
    }
  });

  const splittedProps = splitProps(merged, [
    'label',
    'labelSize',
    'hint',
    'type'
  ]);
  const inputFieldProps: InputFieldProps = splittedProps[0];
  const inputElementProps: InputProps | TextareaProps =
    splittedProps[1] as ElementProps;

  let inputRef: HTMLInputElement | HTMLTextAreaElement | undefined;

  const testId = `field-${inputElementProps.name || inputFieldProps.label || new Date().getTime()}`;

  return (
    <div class={`flex flex-col mb-2`}>
      <Show when={merged.label}>
        <FormLabel
          class={`mb-1 font-medium text-sm`}
          classList={{
            'text-fg-default': !inputElementProps.disabled,
            'text-fg-disabled': inputElementProps.disabled
          }}
          for={inputElementProps.id}
          size={inputFieldProps.labelSize || DEFAULT_LABEL_SIZE}
        >
          {merged.label}
        </FormLabel>
      </Show>
      <Show
        when={inputFieldProps.type === 'textarea'}
        fallback={
          <div
            class={'relative'}
            classList={{
              error: !!fieldError().length
            }}
          >
            <Input
              ref={inputRef as HTMLInputElement}
              size={'sm'}
              classList={{
                error: !!fieldError().length
              }}
              {...(inputElementProps as InputProps)}
              type={inputFieldProps.type}
              disabled={isDisabled()}
              data-testid={testId}
            />
            <Show when={merged.editWithConsent}>
              <Switch>
                <Match when={!!isDisabled()}>
                  <button
                    class={`absolute top-1/2 -mt-[20px] right-[2px] p-2 cursor-pointer opacity-60 hover:opacity-100 transition-opacity delay-75`}
                    onClick={() => {
                      setIsDisabled(false);
                      merged.onEditClick?.();
                      inputRef?.focus();
                    }}
                  >
                    <PencilLine width={18} />
                  </button>
                </Match>
                <Match when={!isDisabled()}>
                  <button
                    class={`absolute top-1/2 -mt-[20px] right-[2px] p-2 cursor-pointer opacity-60 hover:opacity-100 transition-opacity delay-75`}
                    onClick={() => {
                      setIsDisabled(true);
                      merged.onEditCancel?.();
                    }}
                  >
                    <XIcon width={18} />
                  </button>
                </Match>
              </Switch>
            </Show>

            <Show
              when={
                merged.editWithConsent
                  ? !isDisabled() && merged.suffixText
                  : merged.suffixText
              }
            >
              <span
                class={`absolute top-1/2 transform -translate-y-1/2 p-2 text-sm text-fg-subtle`}
                classList={{
                  'right-[25px]': merged.editWithConsent,
                  'right-[2px]': !merged.editWithConsent
                }}
              >
                {merged.suffixText}
              </span>
            </Show>
          </div>
        }
      >
        <Textarea
          ref={inputRef as HTMLTextAreaElement}
          size={'sm'}
          class={'text-sm'}
          classList={{
            error: !!fieldError().length
          }}
          {...(inputElementProps as TextareaProps)}
          data-testid={testId}
        />
      </Show>

      <Switch>
        <Match when={!!fieldError().length}>
          <p class={`text-error text-sm mt-1`}>{fieldError()}</p>
        </Match>
        <Match when={!fieldError().length && !!inputFieldProps.hint}>
          <p
            class={` text-sm mt-1`}
            classList={{
              'text-fg-subtle': !inputElementProps.disabled,
              'text-fg-disabled': inputElementProps.disabled
            }}
          >
            {inputFieldProps.hint}
          </p>
        </Match>
      </Switch>
    </div>
  );
}
