import { createSignal, JSXElement } from 'solid-js';
import { ModalSizes, ModalTypes } from '~/components/ui/Modal/Modal.tsx';
import Modal from '~/components/ui/Modal';
import { Localized } from '~/i18n';
import { Button } from '~/components/ui/Button';
import { removeParamFromUrl } from '~/utils/commonUtils';
import { miscConstants } from '~/consts';
import { globalStore, setGlobalStore } from '~/stores';
import QuickStartOverlay from './QuickStartOverlay';
import { getTranslationKey } from '~/pages/Quickstart/utils.ts';
import WorkspaceAndEnvironmentDescription from './WorkspaceAndEnvironmentDescription';
const BUTTON_TIMER_INTERVAL = 1000;
export default function QuickStartWelcomeModal(): JSXElement {
  if (!globalStore.shouldShowQuickStartModalOnLoad) {
    return null;
  }
  const [timeleft, setTimeleft] = createSignal(5);
  var buttonTimer = setInterval(function () {
    if (timeleft() <= 0) {
      clearInterval(buttonTimer);
    }
    setTimeleft(timeleft() - 1);
  }, BUTTON_TIMER_INTERVAL);
  return (
    <Modal
      title={
        <div class={`text-xl`}>
          <Localized translationKey={getTranslationKey('modal_header')} />
        </div>
      }
      type={ModalTypes.FORM}
      open={true}
      size={ModalSizes.MEDIUM}
      customBackdrop={
        <>
          <QuickStartOverlay
            class="fixed top-[60px] left-[35px] z-[1400]"
            elementId="header-leftSection"
          >
            <WorkspaceAndEnvironmentDescription />
          </QuickStartOverlay>
        </>
      }
    >
      {(closeModal) => {
        return (
          <div>
            <div class={'flex flex-col'}>
              <div class={`flex flex-col`}>
                <p class={`w-full`}>
                  <Localized
                    vars={{
                      bold: (str: string) => (
                        <span class={`font-medium`}>{str}</span>
                      )
                    }}
                    translationKey={getTranslationKey('modal_description')}
                  />
                </p>
                <p class={`w-full mt-4`}>
                  <Localized
                    vars={{
                      bold: (str: string) => (
                        <span class={`font-medium`}>{str}</span>
                      )
                    }}
                    translationKey={getTranslationKey('modal_next_step')}
                  />
                </p>
              </div>

              <div class={`mt-6 text-right`}>
                <Button
                  disabled={timeleft() > 0}
                  onClick={() => {
                    setGlobalStore('shouldShowQuickStartModalOnLoad', false);
                    removeParamFromUrl(
                      miscConstants.QUERY_PARAMS.ONBOARDING_PARAM_NAME
                    );
                    closeModal();
                  }}
                >
                  <Localized
                    vars={{
                      timer_value: timeleft() > 0 ? ` (${timeleft()})` : ''
                    }}
                    translationKey={getTranslationKey('got_it')}
                  />
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Modal>
  );
}
