import { EnvironmentTypes } from '~/api';

export enum FeatureFlagKeys {
  SOCIAL_CONNECTION = 'social_connection',
  MANAGE_ENVIRONMENT = 'manage_environment',
  PORTAL_CUSTOMIZATION = 'portal_customization',
  APPLICATION_SETTINGS = 'application_settings',
  WEBHOOKS = 'webhooks',
  SINGLE_SIGNON = 'sso',
  DIRECTORY_SYNC = 'dir_sync',
  GBRA = 'gbra',
  BILLING = 'billing',
  SIGNUP = 'signup',
  GETTING_STARTED = 'getting_started',
  QUICK_START = 'quick_start',
  USERS = 'users',
  PORTAL_CUSTOMIZATION_DISALLOW_DISABLE_CONNECTION = 'portal_customization_disallow_disable_connection',
  CUSTOM_DOMAIN = 'cname_enabled',
  OAUTH_CONFIGURATION_TYPE_OPTIONS = 'use_platform_credentials_for_environment',
  AUTHENTICATION_METHODS = 'authentication_methods'
}

type FeatureFlagValue = string | boolean;

export interface FeatureSettings {
  name: FeatureFlagKeys;
  enabled: boolean;
}

export interface FeatureFlagData {
  value: FeatureFlagValue;
  key: FeatureFlagKeys;
  reason?: string;
  variant?: string;
  metadata?: Record<string, any>;
}

export interface OpenFeatureListResponse {
  flags: FeatureFlagData[];
}

export type FeatureFlagMap = Partial<
  Record<FeatureFlagKeys, FeatureFlagData>
> | null;

export type GetFeatureFlagRequest = {
  workspaceId: string;
  environmentType: EnvironmentTypes;
  targetingKey: string;
};

export interface ListEnvironmentFeaturesRequest {
  environmentId: string;
}

export interface ListEnvironmentFeaturesResponse {
  features: FeatureSettings[];
}

export interface ToggleEnvironmentFeatureRequest {
  environmentId: string;
  feature: FeatureFlagKeys;
  status: 'enable' | 'disable';
}
