import { createSignal, For, JSXElement, mergeProps, Show } from 'solid-js';
import * as DropdownMenu from '~/components/ui/Menu/Base.tsx';
import { MoreHorizontalIcon } from 'lucide-solid';
import { Button } from '~/components/ui/Button';
import { Tipper } from '~/components/ui/Tooltip';

export type ActionMenuItem = {
  label: JSXElement;
  onClick?: (...args: any[]) => void;
  disabled?: boolean;
  dataTestId?: string;
  tooltipContent?: string;
  hidden?: boolean;
};

type ButtonVariantType = 'solid' | 'outline' | 'ghost' | 'link';

type ActionsDropdownProps = {
  id: string;
  items?: Array<ActionMenuItem>;
  disabled?: boolean;
  children?: JSXElement | ((isOpen: boolean) => JSXElement);
  loading?: boolean;
  dataTestId?: string;
  buttonVariant?: ButtonVariantType;
  containerClass?: string;
};

export function ActionsDropdown(props: ActionsDropdownProps): JSXElement {
  const merged = mergeProps(
    {
      disabled: false,
      loading: false,
      dataTestId: `actions-dropdown-${props.id}`,
      buttonVariant: 'link'
    },
    props
  );

  const [isOpen, setIsOpen] = createSignal(false);

  return (
    <Show
      when={!merged.disabled}
      fallback={
        <Button
          variant={`link`}
          size={'sm'}
          class={`text-fg-disabled cursor-not-allowed`}
        >
          <Show
            when={!merged.children}
            fallback={
              typeof merged.children === 'function'
                ? merged.children(isOpen())
                : merged.children
            }
          >
            <div class={`px-4 py-2`}>
              <MoreHorizontalIcon />
            </div>
          </Show>
        </Button>
      }
    >
      <DropdownMenu.Root
        onOpenChange={({ open: isMenuOpen }) => {
          setIsOpen(isMenuOpen);
        }}
      >
        <DropdownMenu.Trigger asChild>
          <Button
            size={'sm'}
            variant={
              merged.children
                ? (merged.buttonVariant as ButtonVariantType)
                : `link`
            }
            loading={merged.loading}
            data-testid={merged.dataTestId}
          >
            <Show
              when={!merged.children}
              fallback={
                typeof merged.children === 'function'
                  ? merged.children(isOpen())
                  : merged.children
              }
            >
              <div class={`px-4 py-2`}>
                <MoreHorizontalIcon />
              </div>
            </Show>
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Positioner class={merged.containerClass}>
          <DropdownMenu.Content>
            <For each={merged.items}>
              {(menuItem, index) => {
                const itemId = `${merged.id}-${index()}`;
                const showTooltip = !!menuItem.tooltipContent;
                if (!!menuItem.hidden) return <></>;
                return (
                  <Tipper
                    when={showTooltip}
                    successTip={menuItem.tooltipContent!}
                    positioning={{
                      placement: 'bottom-end'
                    }}
                  >
                    <DropdownMenu.Item
                      id={itemId}
                      data-testid={menuItem.dataTestId || itemId}
                      onClick={(e) =>
                        !menuItem.disabled &&
                        menuItem.onClick &&
                        menuItem.onClick(e)
                      }
                      disabled={!!menuItem.disabled}
                    >
                      <div
                        class={`font-normal`}
                        classList={{
                          'text-fg-disabled cursor-not-allowed':
                            !!menuItem.disabled
                        }}
                      >
                        {menuItem.label}
                      </div>
                    </DropdownMenu.Item>
                  </Tipper>
                );
              }}
            </For>
          </DropdownMenu.Content>
        </DropdownMenu.Positioner>
      </DropdownMenu.Root>
    </Show>
  );
}
