import {
  createEffect,
  createSignal,
  JSXElement,
  mergeProps,
  onCleanup,
  Show
} from 'solid-js';
import { createQuery } from '@tanstack/solid-query';
import { miscConstants, timeSignatures } from '~/consts.ts';
import { setGlobalStore } from '~/stores';
import {
  createPortalSession,
  getPortalSessionDetails,
  getPortalSessionDetailsQuery,
  getPortalSessionQuery,
  retryAuthorizedRequests
} from '~/api';
import SkLoader from '~/components/ui/SkLoader';
import { AppErrors } from '~/components/ErrorBoundaries/utils.ts';
import {
  portalSessionExpiryTimerWorkerMessageHandler,
  PortalSessionExpiryWorkerEventTypes,
  WARNING_MINUTES_TILL_SESSION_EXPIRY
} from './utils';
import { useIntl } from '@cookbook/solid-intl';
import portalSessionExpiryTimerWorker from '~/utils/workers/portalSessionExpiryTimerWorker.js';
import logger from '~/utils/logger';

type PortalAuthenticationProps = {
  children: JSXElement;
};

export default function PortalAuthentication(
  props: PortalAuthenticationProps
): JSXElement {
  const merged = mergeProps({}, props);
  const intl = useIntl();
  const [isLoaded, setIsLoaded] = createSignal(false);
  let timerWorker: Worker;

  const params = new URLSearchParams(new URL(window.location.href).search);

  const portalSessionDetailsQuery = createQuery(() => ({
    queryKey: getPortalSessionDetailsQuery(),
    queryFn: async () => await getPortalSessionDetails(),
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  try {
    timerWorker = new Worker(portalSessionExpiryTimerWorker, {
      type: 'module'
    });
  } catch (error) {
    logger.error('Error importing worker script ', error);
  }

  const messageHanlder = (e: MessageEvent) => {
    portalSessionExpiryTimerWorkerMessageHandler(
      e,
      intl,
      portalSessionDetailsQuery.data!.organizationId || '-'
    );
  };

  if (params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID)) {
    const portalSessionQuery = createQuery(() => ({
      queryKey: getPortalSessionQuery(),
      queryFn: async () =>
        await createPortalSession(
          params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_LINK_ID) || ''
        ),
      retry: retryAuthorizedRequests
    }));

    createEffect(() => {
      if (portalSessionQuery.isSuccess) {
        setIsLoaded(true);
        portalSessionQuery.data.accessToken &&
          setGlobalStore(
            'portalAuthToken',
            portalSessionQuery.data.accessToken
          );
      } else if (portalSessionQuery.isError) {
        throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
      }
    });
  } else if (
    params.has(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN)
  ) {
    setGlobalStore(
      'portalAuthToken',
      params.get(miscConstants.QUERY_PARAMS.CUSTOMER_PORTAL_AUTH_TOKEN || '')
    );
    setGlobalStore('shouldForwardToken', true);
    setIsLoaded(true);
  } else {
    setIsLoaded(true);
  }

  // fetched after isLoaded is true
  // to avoid blocking the render path
  createEffect(() => {
    if (isLoaded()) {
      portalSessionDetailsQuery.refetch();
    }
  });

  createEffect(() => {
    if (portalSessionDetailsQuery.isSuccess) {
      const expiryTime =
        portalSessionDetailsQuery.data.sessionExpiry ||
        portalSessionDetailsQuery.data.accessTokenExpiry;

      const expiryTimeDate: Date = new Date(expiryTime);
      setGlobalStore('portalSessionExpiry', expiryTimeDate);
      if (timerWorker) {
        timerWorker?.postMessage({
          event: PortalSessionExpiryWorkerEventTypes.START_TIMER,
          eventTypes: PortalSessionExpiryWorkerEventTypes,
          expiryTime: expiryTimeDate.getTime(),
          warningTime:
            expiryTimeDate.getTime() -
            WARNING_MINUTES_TILL_SESSION_EXPIRY * timeSignatures.MIN_IN_MILLIS
        });
        timerWorker?.addEventListener('message', messageHanlder);
      }
    } else if (portalSessionDetailsQuery.isError) {
      throw new Error(AppErrors.CUSTOMER_PORTAL_LINK_EXPIRED);
    }

    onCleanup(() => {
      if (timerWorker) {
        timerWorker?.postMessage({
          event: PortalSessionExpiryWorkerEventTypes.END_TIMER,
          eventTypes: PortalSessionExpiryWorkerEventTypes
        });
        timerWorker?.removeEventListener('message', messageHanlder);
      }
    });
  });

  createEffect(() => {
    if (isLoaded()) {
      portalSessionDetailsQuery.refetch();
    }
  });

  return (
    <Show
      when={isLoaded()}
      fallback={<SkLoader />}
    >
      {merged.children}
    </Show>
  );
}
