import { JSXElement, Match, mergeProps, Show, Switch } from 'solid-js';
import { Localized } from '~/i18n';
import Link from '../Link';
import { getSlug, PublicPagePath } from '../AppRouter/utils';

export enum LINK_EXPIRED_CONTEXT {
  ADMIN_PORTAL_CONTEXT = 'ADMIN_PORTAL_CONTEXT',
  SIGNUP_CONTEXT = 'SIGNUP_CONTEXT',
  LOGIN_CONTEXT = 'LOGIN_CONTEXT',
  GENERIC_CONTEXT = 'GENERIC_CONTEXT'
}

type PortalLinkExpiredProps = {
  context?: LINK_EXPIRED_CONTEXT;
};

export default function PortalLinkExpired(
  props?: PortalLinkExpiredProps
): JSXElement {
  const merged = mergeProps({}, props);
  return (
    <div class={`flex flex-col w-full justify-center items-center`}>
      <div class={`text-2xl`}>
        <Show
          when={merged.context === LINK_EXPIRED_CONTEXT.ADMIN_PORTAL_CONTEXT}
          fallback={
            <Localized
              translationKey={`pages.link_expired.linked_expired_header`}
            />
          }
        >
          <Localized
            translationKey={`pages.link_expired.admin_portal_linked_expired_header`}
          />
        </Show>
      </div>
      <div class={`mt-9`}>
        <Switch
          fallback={
            <Localized
              translationKey={`pages.link_expired.linked_expired_description`}
            />
          }
        >
          <Match when={merged.context === LINK_EXPIRED_CONTEXT.SIGNUP_CONTEXT}>
            <Localized
              translationKey={`pages.link_expired.signup_link_expired_description`}
              vars={{
                signupLink: (str: string) => (
                  <Link
                    href={'#'}
                    onClick={() => {
                      window.location.assign(
                        `${getSlug.publicPage(PublicPagePath.SIGNUP, true)}`
                      );
                    }}
                    class={'underline hover:cursor-pointer'}
                  >
                    {str}
                  </Link>
                )
              }}
            />
          </Match>
          <Match when={merged.context === LINK_EXPIRED_CONTEXT.LOGIN_CONTEXT}>
            <Localized
              translationKey={`pages.link_expired.login_link_expired_description`}
              vars={{
                loginLink: (str: string) => (
                  <Link
                    href={'#'}
                    onClick={() => {
                      window.location.assign(
                        `${getSlug.publicPage(PublicPagePath.LOGIN, true)}`
                      );
                    }}
                    class={'underline hover:cursor-pointer'}
                  >
                    {str}
                  </Link>
                )
              }}
            />
          </Match>
          <Match
            when={merged.context === LINK_EXPIRED_CONTEXT.ADMIN_PORTAL_CONTEXT}
          >
            <Localized
              translationKey={`pages.link_expired.linked_expired_description`}
            />
          </Match>
        </Switch>
      </div>
    </div>
  );
}
