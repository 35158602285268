export default function printEasterEgg() {
  console.log(
    `%c
    
                                     ╦╦m                ╔╦╦          ▒▒   ╓╔╦      
                                     ╠▒╡                j▒▒                ╠▒Ü   
   ,╓╓╓_      _╓╓╓,_     _,╓╓,       ╠▒╡     _,╓╓╓_     j▒▒               ,╠▒Ü,, 
 ╔╠╠^^^╚╩   ╔╠▒╩╜╚╩▒K  ,D▒▒╩╜╚╬╦▒▒H  ╠▒╡   ╓╠▒╩╙╙╙╬╠╦   j▒▒  ╔╬╠^    ▒▒H └╜╠▒╠╜╜ 
 j▒╬╦²_    j▒╠         ╠▒╩     \`▒▒H  ╠▒╡  ▒▒Ü_____j▒▒   j▒▒@╠╩\`      ▒▒H   ╠▒Ü   
   \`²╚╠▒D_ ╚▒╬         ▒▒H      ▒▒H  ╠▒╡ [▒▒^^^^^^^^^   j▒▒²╠╠╖      ▒▒H   ╠▒Ü   
 ╔K,___╠▒╩  ╠▒K╖,╓╔D╦  ²▒▒K╖,╓╔╠▒▒H  ╠▒╡  ╙▒╠╦,_,╔╦,    j▒▒  ²╠╠╦    ▒▒H   ╚▒▒╓, 
  '╙╚╩╚╙\`    \`²╚╩╩╜^     '╙╩╩╜\`  ╙╙┘ ╙╜╙   '╙╚╩╚╜^      ²╜╜    ²╜╜   -╜╜    ╙╜╜
    `,
    'color: #4F5EFF;'
  );
}
