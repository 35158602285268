import { JSXElement } from 'solid-js';
import {
  ConfigurationTypes,
  ConnectionTypes,
  DirectoryProviders,
  SSOProviders
} from '~/api/types/connectionData.ts';

export type SamlField = {
  enabled: boolean;
  label: JSXElement;
  hintTranslationKey?: string;
};

export type SAMLFields = {
  spAssertionUrl: SamlField;
  spEntityId: SamlField;
  spMetadataUrl: SamlField;
  idpMetadataUrl: SamlField;
  idpEntityId: SamlField;
  idpSsoUrl: SamlField;
  idpCertificate: SamlField;
};

export type SAMLFieldConfig = {
  default: SAMLFields;
  [SSOProviders.OKTA]: SAMLFields;
  [SSOProviders.MICROSOFT_AD]: SAMLFields;
  [SSOProviders.ONELOGIN]: SAMLFields;
};

export const IDPFieldConfig: SAMLFieldConfig = {
  default: {
    spAssertionUrl: {
      enabled: true,
      label: 'ACS URL'
    },
    spEntityId: {
      enabled: true,
      label: 'SP Entity ID'
    },
    spMetadataUrl: {
      enabled: true,
      label: 'SP Metadata URL'
    },
    idpMetadataUrl: {
      enabled: true,
      label: 'Metadata URL'
    },
    idpEntityId: {
      enabled: true,
      label: 'IdP Entity ID'
    },
    idpSsoUrl: {
      enabled: true,
      label: 'IdP Single Sign-on URL'
    },
    idpCertificate: {
      enabled: true,
      label: 'x.509 certificate'
    }
  },
  [SSOProviders.OKTA]: {
    spAssertionUrl: {
      enabled: true,
      label: 'Single Sign-On URL'
    },
    spEntityId: {
      enabled: true,
      label: 'Audience URI (SP Entity ID)'
    },
    spMetadataUrl: {
      enabled: true,
      label: 'Service Provider Metadata URL'
    },
    idpMetadataUrl: {
      enabled: true,
      label: 'Metadata URL'
    },
    idpEntityId: {
      enabled: true,
      label: 'Issuer'
    },
    idpSsoUrl: {
      enabled: true,
      label: 'Sign-On URL'
    },
    idpCertificate: {
      enabled: true,
      label: 'Signing Certificate'
    }
  },
  [SSOProviders.MICROSOFT_AD]: {
    spAssertionUrl: {
      enabled: true,
      label: 'Reply URL (Assertion Consumer Service URL)'
    },
    spEntityId: {
      enabled: true,
      label: 'Identifier (Entity ID)'
    },
    spMetadataUrl: {
      enabled: true,
      label: 'Metadata URL'
    },
    idpMetadataUrl: {
      enabled: true,
      label: 'App Federation Metadata URL'
    },
    idpEntityId: {
      enabled: true,
      label: 'Microsoft Entra Identifier'
    },
    idpSsoUrl: {
      enabled: true,
      label: 'Login URL'
    },
    idpCertificate: {
      enabled: true,
      label: 'Certificate'
    }
  },
  [SSOProviders.ONELOGIN]: {
    spAssertionUrl: {
      enabled: true,
      label: 'ACS (Consumer) URL'
    },
    spEntityId: {
      enabled: true,
      label: 'Audience (Entity ID)'
    },
    spMetadataUrl: {
      enabled: true,
      label: 'Metadata URL'
    },
    idpMetadataUrl: {
      enabled: true,
      label: 'Issuer URL'
    },
    idpEntityId: {
      enabled: true,
      label: 'Issuer URL'
    },
    idpSsoUrl: {
      enabled: true,
      label: 'SAML 2.0 Endpoint (HTTP)'
    },
    idpCertificate: {
      enabled: true,
      label: 'X.509 Certificate'
    }
  }
};

export type ProviderName = {
  label: string;
};

export type ProviderNameConfig = {
  [SSOProviders.OKTA]: ProviderName;
  [SSOProviders.MICROSOFT_AD]: ProviderName;
  [SSOProviders.PING_IDENTITY]: ProviderName;
  [SSOProviders.ONELOGIN]: ProviderName;
  [SSOProviders.GOOGLE]: ProviderName;
  [SSOProviders.JUMPCLOUD]: ProviderName;
  [SSOProviders.CUSTOM]: ProviderName;
};

export const ProviderNames = {
  [SSOProviders.OKTA]: {
    label: 'Okta'
  },
  [SSOProviders.MICROSOFT_AD]: {
    label: 'Entra ID (Azure AD)'
  },
  [SSOProviders.PING_IDENTITY]: {
    label: 'Ping Identity'
  },
  [SSOProviders.ONELOGIN]: {
    label: 'OneLogin'
  },
  [SSOProviders.GOOGLE]: {
    label: 'Google Workspace'
  },
  [SSOProviders.JUMPCLOUD]: {
    label: 'JumpCloud'
  },
  [SSOProviders.CUSTOM]: {
    label: 'Custom Provider'
  },
  [DirectoryProviders.CUSTOM]: {
    label: 'Custom Provider'
  },
  [SSOProviders.IDP_SIMULATOR]: {
    label: 'IdP Simulator'
  }
};

export const ConnectionTypeDisplayName: Partial<
  Record<ConnectionTypes, { label: string; availableForSSO: boolean }>
> = {
  [ConnectionTypes.SAML]: {
    label: 'SAML 2.0',
    availableForSSO: true
  },
  [ConnectionTypes.OIDC]: {
    label: 'OIDC - OpenID Connect',
    availableForSSO: true
  },
  [ConnectionTypes.OAUTH]: {
    label: 'OAUTH 2.0',
    availableForSSO: false
  }
};

export function getProviderName(provider: string = SSOProviders.CUSTOM) {
  return ProviderNames[provider].label;
}

export type ConfigurationConfig = {
  default: ConfigurationTypes;
  allowedConfigurations: ConfigurationTypes[];
};

export type IDPConfigurationConfig = {
  default: ConfigurationConfig;
  [SSOProviders.GOOGLE]: ConfigurationConfig;
};

export const SamlIDPConfigType: IDPConfigurationConfig = {
  default: {
    default: ConfigurationTypes.DISCOVERY,
    allowedConfigurations: [
      ConfigurationTypes.DISCOVERY,
      ConfigurationTypes.MANUAL
    ]
  },
  [SSOProviders.GOOGLE]: {
    default: ConfigurationTypes.MANUAL,
    allowedConfigurations: [ConfigurationTypes.MANUAL]
  }
};
