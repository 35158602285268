import { JSXElement, mergeProps, Show, splitProps } from 'solid-js';
import { PositioningOptions } from '@zag-js/popper';
import Tooltip from './Tooltip';

type TipperProps = {
  enabled?: boolean;
  when: boolean;
  successTip: string;
  fallbackTip?: string;
  children: JSXElement;
  positioning?: PositioningOptions;
  class?: string;
};

export default function Tipper(props: TipperProps): JSXElement {
  const merged = mergeProps(
    {
      enabled: true,
      fallbackTip: '',
      class: ''
    },
    props
  );
  const [tooltipProps, restProps] = splitProps(merged, ['positioning']);

  const getContent = (): string =>
    restProps.when ? restProps.successTip : restProps.fallbackTip;

  return (
    <Show
      when={restProps.enabled && !!getContent()}
      fallback={restProps.children}
    >
      <Tooltip
        content={getContent()}
        {...tooltipProps}
        triggerClass={restProps.class}
      >
        {restProps.children}
      </Tooltip>
    </Show>
  );
}
