import {
  ApiErrorInstance,
  isApi5xxResponseStatus,
  withHandleError
} from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';

const handleOnboradingError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  const translationBasePath = 'server_errors.onboarding';
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(`${translationBasePath}.failed`, {
      intl
    });
  }
});
const getTranslationKey = (path: string): string => `pages.onboarding.${path}`;

export { handleOnboradingError, getTranslationKey };
