import {
  createContext,
  createEffect,
  JSXElement,
  mergeProps,
  untrack,
  useContext
} from 'solid-js';
import { createStore } from 'solid-js/store';
import { createQuery } from '@tanstack/solid-query';
import {
  EnvironmentData,
  ERROR_CODES,
  getCurrentEnvironmentList,
  getCurrentLoggedInMemeberKey,
  getCurrentUserDetails,
  getCurrentWorkspaceDetails,
  getCurrentWorkspaceKey,
  getEnviornmentListsKey,
  MemberData,
  retryAuthorizedRequests,
  WorkspaceData
} from '~/api';
import { useNavigate, useParams } from '@solidjs/router';
import { miscConstants, timeSignatures } from '~/consts.ts';
import { setGlobalStore } from '~/stores';
import { identifyUser } from '~/utils/productLogging/posthog.ts';
import { getSlug, WorkspacePagePath } from '../AppRouter/utils';
import { HttpStatusCode } from 'axios';

const AppDataContext = createContext();

type AppDataProviderProps = {
  children: (...params: any[]) => JSXElement;
};

export type AppDataContextValueType = {
  member: MemberData | null;
  workspace: WorkspaceData | null;
  environments: Array<EnvironmentData>;
  selectedEnvironment: EnvironmentData | null;
  isLoggedIn: boolean;
  setWorkspaceData: (workspaceData: WorkspaceData) => void;
  isInitiallyLoaded: boolean;
  refetchEnvironments: () => Promise<void>;
};

export default function AppDataProvider(
  props: AppDataProviderProps
): JSXElement {
  const merged = mergeProps({}, props);
  const params = useParams();
  const navigate = useNavigate();

  const [isDataFetched, setIsDataFetched] = createStore({
    memberQuery: false,
    workspaceQuery: false,
    environmentQuery: false
  });
  const [appData, setAppData] = createStore<AppDataContextValueType>({
    member: null,
    workspace: null,
    environments: [],
    isLoggedIn: false,
    selectedEnvironment: null,
    setWorkspaceData: (data) => {
      setAppData('workspace', data);
    },
    isInitiallyLoaded: false,
    refetchEnvironments: async () => {
      await environmentsQuery.refetch();
    }
  });

  const currentMemberQuery = createQuery(() => ({
    queryKey: getCurrentLoggedInMemeberKey(),
    queryFn: async () => {
      return await getCurrentUserDetails();
    },
    retry: retryAuthorizedRequests,
    refetchOnWindowFocus: true,
    staleTime: 30 * timeSignatures.SEC_IN_MILLIS
  }));

  createEffect(() => {
    if (currentMemberQuery.isSuccess) {
      untrack(() => {
        if (
          appData.isInitiallyLoaded &&
          appData.member?.id !== currentMemberQuery.data?.id
        ) {
          window.location.assign(`${getSlug.workspaceBaseNamespace()}/`);
          return;
        }
        setAppData('member', currentMemberQuery.data);
        setAppData('isLoggedIn', true);
        setIsDataFetched('memberQuery', true);
      });
      identifyUser(currentMemberQuery.data);
      workspaceQuery.refetch();
      environmentsQuery.refetch();
    }
    if (currentMemberQuery.isError) {
      if (
        currentMemberQuery?.error?.code === ERROR_CODES.UNAUTHORIZED ||
        currentMemberQuery?.error?.httpStatus === HttpStatusCode.NotFound
      ) {
        untrack(() => {
          setAppData('member', null);
          setAppData('isLoggedIn', false);
          setIsDataFetched('memberQuery', true);
          // As the other queries are not needed for the login page
          // we can ignore them and mark them as fetched
          // so that children continues to render
          setIsDataFetched('workspaceQuery', true);
          setIsDataFetched('environmentQuery', true);
        });
      }
      // @todo handle other errors
    }
  });

  const workspaceQuery = createQuery(() => ({
    queryKey: getCurrentWorkspaceKey(),
    queryFn: async () => {
      return await getCurrentWorkspaceDetails();
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (workspaceQuery.isSuccess && workspaceQuery.data) {
      untrack(() => {
        setAppData('workspace', workspaceQuery.data);
        setIsDataFetched('workspaceQuery', true);
      });
    }
    if (workspaceQuery.isError) {
      if (workspaceQuery?.error?.code === ERROR_CODES.UNAUTHORIZED) {
        untrack(() => {
          setAppData('workspace', null);
          setIsDataFetched('workspaceQuery', true);
        });
      }
      // @todo handle other errors
    }
  });

  const environmentsQuery = createQuery(() => ({
    queryKey: getEnviornmentListsKey(),
    queryFn: async () => {
      return await getCurrentEnvironmentList();
    },
    retry: retryAuthorizedRequests,
    enabled: false
  }));

  createEffect(() => {
    if (environmentsQuery.isSuccess && environmentsQuery.data) {
      untrack(() => {
        if (!environmentsQuery.data.length) {
          navigate(
            getSlug.workspace(WorkspacePagePath.WORKSPACE_ONBOARDING, true)
          );
        }
        setAppData('environments', environmentsQuery.data);
        setIsDataFetched('environmentQuery', true);
      });
    }
    if (environmentsQuery.isError) {
      if (environmentsQuery?.error?.code === ERROR_CODES.UNAUTHORIZED) {
        untrack(() => {
          setAppData('environments', []);
          setIsDataFetched('environmentQuery', true);
        });
      }
      // @todo handle other errors
    }
  });

  createEffect(() => {
    if (getIsAllDataFetched() && !appData.isInitiallyLoaded) {
      untrack(() => setAppData('isInitiallyLoaded', true));
    }
  });

  createEffect(() => {
    if (appData.environments.length) {
      let selectedEnvironment = appData.environments[0];
      const envId =
        params.environmentId ||
        localStorage.getItem(
          miscConstants.localStorageKeys.getEnvironmentSelection()
        );
      const envsToSelect = appData.environments.filter(
        ({ id }) => id === envId
      );
      if (envsToSelect.length) {
        selectedEnvironment = envsToSelect[0];
      }

      setAppData('selectedEnvironment', selectedEnvironment);
      setGlobalStore('currentEnvDomain', selectedEnvironment.domain!);
      localStorage.setItem(
        miscConstants.localStorageKeys.getEnvironmentSelection(),
        selectedEnvironment.id
      );
    }
  });

  const getIsAllDataFetched = () => {
    return (
      isDataFetched.memberQuery &&
      isDataFetched.workspaceQuery &&
      isDataFetched.environmentQuery
    );
  };

  return (
    <AppDataContext.Provider value={appData}>
      {merged.children(getIsAllDataFetched())}
    </AppDataContext.Provider>
  );
}

export const useAppData = (): AppDataContextValueType =>
  useContext(AppDataContext) as AppDataContextValueType;
