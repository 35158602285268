import { JSXElement, Show } from 'solid-js';
import { useQueryClient } from '@tanstack/solid-query';
import { ModalSizes, ModalTypes } from '~/components/ui/Modal/Modal.tsx';
import Modal from '~/components/ui/Modal';
import InputField from '~/components/ui/Forms/InputField.tsx';
import { Localized } from '~/i18n';
import { useAppData } from '~/components/AppData';
import { getLocalizedString } from '~/i18n/utils.ts';
import {
  MemberData,
  MemberDataUpdateRequest,
  updateLoggedInMember
} from '~/api';
import Form, { ServerError } from '~/components/Form';
import * as zod from 'zod';
import SubmitButton from '~/components/Form/SubmitButton.tsx';
import { validator } from '@felte/validator-zod';
import { cloneObject } from '~/utils/commonUtils.ts';
import Alert from '~/components/ui/Alert';
import {
  handleOnboradingError,
  getTranslationKey
} from '~/pages/Onboarding/utils';

export default function UserOnboardingModal(): JSXElement {
  const queryClient = useQueryClient();

  const appData = useAppData();

  const schema = zod.object({
    firstName: zod
      .string()
      .min(
        1,
        getLocalizedString(
          'commons.onboarding.validation_message_empty_first_name'
        )
      ),
    lastName: zod.string()
  });

  return (
    <Modal
      title={
        <div class={`text-3xl`}>
          <Localized translationKey={getTranslationKey('header')} />
        </div>
      }
      type={ModalTypes.FORM}
      open={true}
      size={ModalSizes.LARGE}
    >
      {(closeModal) => {
        return (
          <Form
            initialValues={{
              firstName: appData.member?.firstName || '',
              lastName: appData.member?.lastName || ''
            }}
            extend={validator({ schema })}
            onSubmit={async ({ firstName, lastName }) => {
              const data: MemberDataUpdateRequest = lastName
                ? { firstName, lastName }
                : { firstName };
              return new Promise((resolve, reject) => {
                updateLoggedInMember(data)
                  .then(async () => {
                    queryClient.setQueryData(
                      ['logged_in_member_details'],
                      (cachedData: MemberData) => {
                        return {
                          ...cloneObject(cachedData),
                          firstName,
                          lastName
                        };
                      }
                    );
                    closeModal();
                    resolve(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    reject(err);
                  });
              });
            }}
            errorHandler={handleOnboradingError}
          >
            {() => {
              return (
                <div class={'flex flex-col'}>
                  <ServerError>
                    {({ message }) => (
                      <Show when={message}>
                        <Alert variant={'error'}>{message}</Alert>
                      </Show>
                    )}
                  </ServerError>
                  <p class={`w-full`}>
                    <Localized
                      translationKey={getTranslationKey('new_user_subheader')}
                    />
                  </p>
                  <div class={`flex mt-6`}>
                    <div class={`flex-grow`}>
                      <InputField
                        label={getLocalizedString(
                          `input_labels.onboarding_first_name`
                        )}
                        required
                        id={`first_name`}
                        placeholder={getLocalizedString(
                          `input_placeholders.onboarding_first_name`
                        )}
                        name={'firstName'}
                      />
                    </div>
                    <div class={`flex-grow ml-4`}>
                      <InputField
                        label={getLocalizedString(
                          `input_labels.onboarding_last_name`
                        )}
                        id={`last_name`}
                        placeholder={getLocalizedString(
                          `input_placeholders.onboarding_last_name`
                        )}
                        name={'lastName'}
                      />
                    </div>
                  </div>

                  <div class={`mt-6 text-center`}>
                    <SubmitButton
                      type={`submit`}
                      allowUnchanged
                    >
                      <Localized translationKey={`cta_labels.continue`} />
                    </SubmitButton>
                  </div>
                </div>
              );
            }}
          </Form>
        );
      }}
    </Modal>
  );
}
