export enum DIRECTORY_SYNC_TAB_TYPES {
  ROLES = 'roles'
}

const TRANSLATION_BASE_PATH = `pages.authentication.directory_sync`;

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

export { getTranslationKey };
