export interface GenerateWebhookPortalLinkResponse {
  url: string;
}

export interface WebhookEndpointData {
  id: string;
  url: string;
  description: string;
  channels: string[] | null;
  disabled: boolean;
  filterTypes: string[] | null;
  metadata: Record<string, any>;
  rateLimit: number | null;
  uid: string | null;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export interface WebhookEndpointMessageData {
  eventId: string | null;
  eventType: string;
  channels: string[] | null;
  id: string | null;
  nextAttempt: string | null;
  timestamp: string;
  status: number;
  payload?: {
    data: object;
    environmentId: string;
    id: string;
    object: string;
    occurredAt: string;
    organizationId: string;
    specVersion: string;
    type: string;
  };
  rawPayload: string;
}

export interface WebhookDeliveryAttemptData {
  endpointId: string;
  id: string;
  msgId: string;
  response: string;
  responseDurationMs: number;
  responseStatusCode: number;
  status: number;
  timestamp: string;
  triggerType: number;
  url: string;
}

export interface WebhookEventTypeData {
  name: string;
  description: string;
  featureFlag: string | null;
  schemas: Record<any, any> | null;
  deprecated: boolean;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum WebhookEvents {
  DIR_ENABLED = 'organization.directory_enabled',
  DIR_DISABLED = 'organization.directory_disabled',
  DIR_DELETED = 'organization.directory_deleted',
  DIR_GROUP_CREATED = 'organization.directory.group_created',
  DIR_GROUP_UPDATED = 'organization.directory.group_updated',
  DIR_GROUP_DELETED = 'organization.directory.group_deleted',
  DIR_USER_CREATED = 'organization.directory.user_created',
  DIR_USER_UPDATED = 'organization.directory.user_updated',
  DIR_USER_DELETED = 'organization.directory.user_deleted',
  ORG_SSO_ENABLED = 'organization.sso_enabled',
  ORG_SSO_DISABLED = 'organization.sso_disabled',
  ORG_SSO_CREATED = 'organization.sso_created',
  ORG_SSO_DELETED = 'organization.sso_deleted'
}
