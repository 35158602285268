import { ERROR_CODES } from '~/api/serverErrors.ts';
import { HttpStatusCode } from 'axios';

type CreateApiErrorParams = {
  errorCode: ERROR_CODES;
  status: HttpStatusCode;
  error?: Record<string, any>;
};

export interface ApiErrorInstance {
  httpStatus: number;
  code: string;
  stack?: string;
  error?: Record<string, any>;
}

export class ApiError extends Error {
  #code = '';
  #httpStatus = 0;
  #error: Record<string, any> = {};

  constructor({ errorCode, status, error = {} }: CreateApiErrorParams) {
    super(error.detail || '');
    this.#code = errorCode;
    this.#httpStatus = status;
    this.#error = error;
  }
  get httpStatus() {
    return this.#httpStatus;
  }
  get code() {
    return this.#code;
  }

  get error() {
    return this.#error;
  }
}

export function createApiError({
  errorCode,
  status,
  error
}: CreateApiErrorParams): ApiError {
  return new ApiError({ errorCode, status, error });
}

export function isApiError(error: any): error is ApiError {
  return error instanceof ApiError;
}
