'use strict';

var portalSessionExpiryTimerWorker = function portalSessionExpiryTimerWorker() {
  var intervalTime = 10000;
  var timerIntervalId,
    timerTimeoutId,
    isWarningMessageSent = false,
    isExpirationMessageSent = false;

  function sendMessage(eventType) {
    self.postMessage({
      eventType: eventType
    });
  }

  function handleWarning(currentTime, nextIntervalTickTime, warningTime) {
    // Trigger event when link is about to expire in 5 minutes
    if (
      !isWarningMessageSent &&
      currentTime <= warningTime &&
      nextIntervalTickTime >= warningTime
    ) {
      var offset = warningTime - currentTime;
      isWarningMessageSent = true;
      timerTimeoutId = setTimeout(function () {
        sendMessage('warning');
      }, offset);
    }
  }

  function handleExpiration(currentTime, nextIntervalTickTime, expiryTime) {
    // Trigger event when link is expired
    if (
      !isExpirationMessageSent &&
      currentTime <= expiryTime &&
      nextIntervalTickTime >= expiryTime
    ) {
      var offset = expiryTime - currentTime;
      isExpirationMessageSent = true;
      timerTimeoutId = setTimeout(function () {
        sendMessage('expiry');
      }, offset);
      clearInterval(timerIntervalId);
    }
  }

  function startTimer(expiryTime, warningTime) {
    if (warningTime < Date.now()) {
      sendMessage('warning');
      isWarningMessageSent = true;
    }
    if (expiryTime < Date.now()) {
      sendMessage('expiry');
      isExpirationMessageSent = true;
      return;
    }
    timerIntervalId = setInterval(function () {
      var currentTime = Date.now();
      var nextIntervalTickTime = currentTime + intervalTime;
      handleWarning(currentTime, nextIntervalTickTime, warningTime);
      handleExpiration(currentTime, nextIntervalTickTime, expiryTime);
    }, intervalTime);
  }

  function stopTimer() {
    clearInterval(timerIntervalId);
    clearTimeout(timerTimeoutId);
  }

  self.addEventListener('message', function (e) {
    var eventData = e.data,
      event = eventData.event,
      eventTypes = eventData.eventTypes,
      expiryTime = eventData.expiryTime,
      warningTime = eventData.warningTime;
    switch (event) {
      case eventTypes.START_TIMER: {
        startTimer(expiryTime, warningTime);
        break;
      }
      case eventTypes.END_TIMER: {
        stopTimer();
        break;
      }
    }
  });
};

let code = portalSessionExpiryTimerWorker.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const workerScript = URL.createObjectURL(blob);

export default workerScript;
