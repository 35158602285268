import { Component, createEffect, Show } from 'solid-js';
import { useLocation, useNavigate } from '@solidjs/router';
import { useAppData } from '~/components/AppData';
import { getSlug, PublicPagePath } from '~/components/AppRouter/utils.ts';
import QuickStartWelcomeModal from '~/pages/Quickstart/QuickStartWelcomeModal';
import UserOnboardingModal from '~/pages/Onboarding/UserOnboardingModal';

export default function withSession(component: Component): Component {
  const Renderer = component;
  return () => {
    const appData = useAppData();
    const location = useLocation();
    const navigate = useNavigate();

    createEffect(() => {
      if (!appData) {
        // Someone accesses the base path `/`
        navigate(`${getSlug.workspaceBaseNamespace()}`, {
          replace: true
        });
      } else if (!appData.isLoggedIn) {
        const urlParams = new URLSearchParams({
          redirectTo: location.pathname
        });
        navigate(
          `${getSlug.workspaceBaseNamespace()}${getSlug.publicPage(PublicPagePath.LOGIN)}?${urlParams.toString()}`,
          {
            replace: true
          }
        );
      }
    });

    function shouldShowUserOnboarding() {
      //This modal logic should work only for the new invited user
      return !!appData.environments?.length && !appData.member?.firstName;
    }

    return (
      <Show when={appData?.isLoggedIn}>
        {shouldShowUserOnboarding() && <UserOnboardingModal />}
        <QuickStartWelcomeModal />
        <Renderer />
      </Show>
    );
  };
}
