import { getLocalizedString } from '~/i18n/utils';
import { globalStore } from '~/stores';
import { postClientNotificationMessage } from '~/utils/clientNotifications/utils';
import { interpolateVarsInString } from '~/utils/commonUtils';

export enum PortalSessionExpiryWorkerEventTypes {
  START_TIMER = 'start-timer',
  END_TIMER = 'end-timer'
}

export const WARNING_MINUTES_TILL_SESSION_EXPIRY = 5;

export const portalSessionExpiryTimerWorkerMessageHandler = (
  e: MessageEvent,
  intl: any,
  orgId?: string
) => {
  if (e.data.eventType === 'warning') {
    postClientNotificationMessage({
      event_type: 'PORTAL_SESSION_WARNING',
      object: 'session',
      message: interpolateVarsInString(
        getLocalizedString(`admin_portals.link_expiry.portal_session_warning`, {
          intl
        }),
        {
          minutesToExpiry: String(WARNING_MINUTES_TILL_SESSION_EXPIRY)
        }
      ),
      organization_id: orgId,
      data: {
        expiry: globalStore.portalSessionExpiry
      }
    });
  }

  if (e.data.eventType === 'expiry') {
    postClientNotificationMessage({
      event_type: 'PORTAL_SESSION_EXPIRY',
      object: 'session',
      message: getLocalizedString(
        `admin_portals.link_expiry.portal_session_expiry`,
        { intl }
      ),
      organization_id: orgId,
      data: {
        expiry: globalStore.portalSessionExpiry
      }
    });
  }
};
