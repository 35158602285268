import {
  ApiErrorInstance,
  isApi5xxResponseStatus,
  withHandleError
} from '~/api';
import { HttpStatusCode } from 'axios';
import { getLocalizedString } from '~/i18n/utils.ts';
import { isFeatureEnabled } from '~/utils/features/featureFlag';
import { FeatureFlagKeys } from '~/api/types/featureFlagData';
import {
  ApplicationCustomizationSettings,
  PortalCustomizationSettings
} from '~/api/types/customisationData';

const TRANSLATION_BASE_PATH = `pages.customization`;

const getTranslationKey = (translationStr: string): string =>
  `${TRANSLATION_BASE_PATH}.${translationStr}`;

const DEFAULT_COLORS = {
  buttonColor: '#000000',
  textColor: '#ffffff'
};

const getHoverColor = (color: string, alpha: number): string => {
  if (!color.includes(`rgba`)) {
    return color;
  }
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  rgba[3] = String(parseInt(rgba[3]) - alpha);
  return `rgba(${rgba.join(',')})`;
};

const errorTranslationBasePath = 'server_errors.customizations';

const handleUpdateCustomizationError = withHandleError(({ error, intl }) => {
  const serverError = error as ApiErrorInstance;
  if (
    serverError.httpStatus === HttpStatusCode.BadRequest ||
    isApi5xxResponseStatus(serverError.httpStatus)
  ) {
    return getLocalizedString(`${errorTranslationBasePath}.update_failed`, {
      intl
    });
  }
});

const getDefaultPortalCustomizationSettings =
  (): PortalCustomizationSettings => {
    return {
      logoUrl: '',
      iconUrl: '',
      buttonColor: DEFAULT_COLORS.buttonColor,
      textColor: DEFAULT_COLORS.textColor,
      ...(isFeatureEnabled(
        FeatureFlagKeys.PORTAL_CUSTOMIZATION_DISALLOW_DISABLE_CONNECTION
      ) && { disallowDisableEnabledConnection: false })
    };
  };

const getDefaultApplicationCustomizationSettings =
  (): ApplicationCustomizationSettings => {
    return {
      name: '',
      url: '',
      supportEmail: ''
    };
  };

enum CUSTOMIZATION_TAB_TYPES {
  BRANDING_AND_THEMING = 'branding',
  CUSTOM_DOMAIN = 'custom-domain',
  APPLICATION_SETTINGS = 'application-settings'
}

export {
  getTranslationKey,
  DEFAULT_COLORS,
  getHoverColor,
  handleUpdateCustomizationError,
  getDefaultPortalCustomizationSettings,
  getDefaultApplicationCustomizationSettings,
  CUSTOMIZATION_TAB_TYPES
};
